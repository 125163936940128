export const webStoreOriginReg = /ad(-boe)?.oceanengine.com/;

const eventSource = {
  source: null as null | WindowProxy,
  origin: '',
};

const secret = { id: '' };

export const postMessageBack = (
  type: string,
  data: Record<string, unknown> = {}
) => {
  if (!eventSource.source) {
    return;
  }
  const { id } = secret;
  eventSource.source.postMessage({ id, type, data }, eventSource.origin);
};

export const checkMessage = (e: MessageEvent) => {
  const { origin, data, source } = e;
  if (!webStoreOriginReg.test(origin)) {
    return false;
  }
  Object.assign(eventSource, { origin, source });
  const { id } = secret;
  const { id: targetId } = data;
  if (!id && targetId) {
    secret.id = targetId;
  }

  return secret.id === data.id;
};
