/** 向 parent window 发送的事件 */
export enum TO_PARENT_EVENT {
  riskCheck = 'riskCheck',
  setIframeAlive = 'setIframeAlive',
  initSlardar = 'initSlardar',
}

/** 从 parent window 接收到的事件 */
export enum FROM_PARENT_EVENT {
  getIframeConnectConfirm = 'getIframeConnectConfirm',
  INIT_USER_APP = 'INIT_USER_APP',
}

/** slardar 自定义事件 */
export enum SLARDAR_EVENT {
  overall = 'overall',
  request = 'request',
}
