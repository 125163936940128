import { refreshPreStartContext } from '@slardar/integrations';
import browserClient, { PerformancePayload } from '@slardar/web';
import { SLARDAR_EVENT } from './event-enums';

/** 初始化配置，监控启动，但是请求不发送。beforeSend 中做一些操作 */
export const initSlardar = ({ getTimeOnPage }: { getTimeOnPage: () => number }) => {
  browserClient('init', {
    bid: 'webstore_app',
    // 以 start 时的 context 刷新 start 之前预发送队列里的 context (https://slardar.cn.goofy.app/docs/sdk/advanced/integrations#refreshprestartcontext)
    integrations: [refreshPreStartContext()],
    plugins: {
      blankScreen: {
        rootSelector: 'body',
      },
    },
  });
  const { host, pathname } = window.location;
  const url = `${host}${pathname}`;
  const overallPayload = {
    url,
    timeOnPage: 0,
    fcp: 0,
    jsErrorCount: 0,
  };
  browserClient('on', 'beforeSend', (ev) => {
    const { common, ev_type, payload } = ev;
    console.log('[webstore-monitor] beforeSend', { common, ev_type, payload });
    // 因为 pid 是手动设置的，因此把第一次默认 init pageview 禁用掉
    if (ev_type === 'pageview' && payload.source === 'init') {
      return false;
    }
    // 记录 fcp
    if (
      ev_type === 'performance' &&
      (payload as PerformancePayload).name === 'fcp'
    ) {
      const { value } = payload as PerformancePayload;
      overallPayload.fcp = value;
    }
    // 累加 js_error 数
    if (ev_type === 'js_error') {
      overallPayload.jsErrorCount += 1;
    }
    // 上报 http 请求
    if (ev_type === 'http') {
      const { context, user_id } = common;
      const { duration, request, response: { status } } = payload;
      const value = {
        url,
        userId: user_id,
        appId: context && context.appId,
        cloudAppId: context && context.cloudAppId,
        apiUrl: request.url,
        apiDuration: duration,
        apiStatus: status,
      };
      sendSlardarEvent({
        name: SLARDAR_EVENT.request,
        categories: { value: JSON.stringify(value) },
      })
    }
    return ev;
  });
  window.addEventListener('beforeunload', () => {
    Object.assign(overallPayload, {
      userId: browserClient.config()?.userId,
      appId: browserClient.context?.get('appId'),
      cloudAppId: browserClient.context?.get('cloudAppId'),
      timeOnPage: getTimeOnPage(),
    })
    console.log('[webstore-monitor] ready to send overall event', JSON.stringify(overallPayload));
    sendSlardarEvent({
      name: SLARDAR_EVENT.overall,
      categories: { value: JSON.stringify(overallPayload) },
    });
    browserClient.getSender()?.flush();
  });
};

/**
 * 如果存在 appId，说明在应用市场页面内
 * 尝试获取连山前端应用托管 id (cloudAppId)
 * */
export const configSlardar = ({
  appId,
  userId,
}: {
  appId?: string | number;
  userId?: string;
}) => {
  let cloudAppId;
  if (location.host.includes('oe-appstore.com')) {
    cloudAppId = location.host.replace(/\D/g, "");
  }
  browserClient('context.set', 'cloudAppId', cloudAppId || '-1');
  browserClient('context.set', 'appId', appId ? String(appId) : '-1');
  if (userId) {
    browserClient('config', { userId });
  }
  const { host, pathname } = window.location;
  let pid = `${host}${pathname}`;
  if (appId) {
    pid = String(appId);
  } else if (cloudAppId) {
    pid = `web-${cloudAppId}`;
  }
  browserClient('config', { pid });
  browserClient('start');
};

interface SlardarEventPayload {
  name: string;
  metrics?: {
    [key: string]: number;
  };
  categories?: {
    [key: string]: string;
  };
}
/** 发送 slardar 自定义事件 */
export const sendSlardarEvent = ({
  name,
  metrics,
  categories,
}: SlardarEventPayload) => {
  browserClient('sendEvent', { name, metrics, categories });
};

interface SlardarLogPayload {
  level: 'info' | 'debug' | 'warn' | 'error';
  content: string;
  extra?: { [key: string]: string | number };
}
/** 发送 slardar 自定义日志 */
export const sendSlardarLog = ({
  level,
  content,
  extra,
}: SlardarLogPayload) => {
  browserClient('sendLog', { level, content, extra });
};
