import {
  initSlardar,
  configSlardar,
} from 'utils/setup-slardar';
import {
  postMessageBack,
  checkMessage,
  webStoreOriginReg,
} from 'utils/message-helper';
import BrowserInteractionTime from 'utils/browser-interaction-time';
import {
  FROM_PARENT_EVENT,
  TO_PARENT_EVENT,
} from 'utils/event-enums';

declare global {
  interface Window {
    /** 避免反复监听 */
    __webstore_slardar__: boolean;
  }
}

// 需要过滤掉的标签名
const filterTagArr = ['SCRIPT', 'NOSCRIPT', 'STYLE', 'SVG'];
/** 敏感词检测 */
function riskCheck() {
  const childrenArr = Array.from(document.body.children);
  let text = '';
  childrenArr.forEach((node) => {
    if (!filterTagArr.includes(node.tagName.toUpperCase())) {
      text += node.textContent;
    }
  });
  // 过滤所有的数字（安全合规，避免广告主数据暴露给天枢）
  text = text.replace(/[0-9]/g, '*');
  postMessageBack(TO_PARENT_EVENT.riskCheck, {
    url: window.location.href,
    text,
  });
  setTimeout(riskCheck, 60 * 1000);
}

/** 用户停留时长 */
const sendTimeOnPage = () => {
  const MARK_IDLE = 'idle';
  const MARK_TAB_INACTIVE = 'tab-inactive';
  const browserInteractionTime: BrowserInteractionTime =
    new BrowserInteractionTime({
      checkCallbacksIntervalMs: 500,
      idleTimeoutMs: 3000,
      idleCallbacks: [
        () => {
          // console.log('idle callback triggered');
          browserInteractionTime.mark(MARK_IDLE);
        },
      ],
      browserTabInactiveCallbacks: [
        () => {
          // console.log('browserTabInactive callback triggered');
          browserInteractionTime.mark(MARK_TAB_INACTIVE);
        },
      ],
    });
  browserInteractionTime.mark(MARK_IDLE);
  browserInteractionTime.mark(MARK_TAB_INACTIVE);
  return () => browserInteractionTime.getTimeInMilliseconds();
};

function messageHandler(e: MessageEvent) {
  if (!checkMessage(e)) {
    return;
  }

  const { data } = e;
  const { type } = data;

  if (type === FROM_PARENT_EVENT.getIframeConnectConfirm) {
    postMessageBack(TO_PARENT_EVENT.setIframeAlive, { isConnect: true });
  } else if (type === FROM_PARENT_EVENT.INIT_USER_APP) {
    postMessageBack(TO_PARENT_EVENT.initSlardar, { initSlardar: true });
    configSlardar({ appId: data.data.appId, userId: data.data.userId });
    riskCheck();
  }
}

const { ancestorOrigins } = document.location;

if (!window.__webstore_slardar__) {
  // 判断当前是在 oceanengine.com 下的 iframe 中，即应用市场内
  if (ancestorOrigins.length > 0 && webStoreOriginReg.test(ancestorOrigins[0])) {
    window.__webstore_slardar__ = true;
    console.log(`[webstore-monitor] enabled, version: ${version}`);
    const getTimeOnPage = sendTimeOnPage();
    initSlardar({ getTimeOnPage });
    window.addEventListener('message', messageHandler, false);
  } else { // 独立页面
    window.__webstore_slardar__ = true;
    console.log(`[webstore-monitor] enabled, version: ${version}`);
    const getTimeOnPage = sendTimeOnPage();
    initSlardar({ getTimeOnPage });
    window.addEventListener('message', messageHandler, false);
    // 直接初始化 slardar
    configSlardar({});
  }
}
